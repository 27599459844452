// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-b-container{
    position: relative;
    top: 0px;
    width: 100%;
    background-color: rgba(67, 67, 67, 0.27);
}

.navbar-b{
    width: 100%;
    border-bottom: 2px solid rgba(67, 67, 67, 0.6);
}

.navbar-b ul {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.navbar-b ul li {
    margin-left: 10px;
    z-index: 1000;
}

.logo-li-b img{
    width: 70px;
}

.name-li-b img{
    width: 150px;
}

@media(max-width:320px){
    .logo-li-b img{
        width: 30px;
    }

    .name-li-b img{
        width: 90px;
    }

    .navbar-b ul{
        padding:20px 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Browse/Navbar-b.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,8CAA8C;AAClD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".navbar-b-container{\n    position: relative;\n    top: 0px;\n    width: 100%;\n    background-color: rgba(67, 67, 67, 0.27);\n}\n\n.navbar-b{\n    width: 100%;\n    border-bottom: 2px solid rgba(67, 67, 67, 0.6);\n}\n\n.navbar-b ul {\n    display: flex;\n    align-items: center;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n.navbar-b ul li {\n    margin-left: 10px;\n    z-index: 1000;\n}\n\n.logo-li-b img{\n    width: 70px;\n}\n\n.name-li-b img{\n    width: 150px;\n}\n\n@media(max-width:320px){\n    .logo-li-b img{\n        width: 30px;\n    }\n\n    .name-li-b img{\n        width: 90px;\n    }\n\n    .navbar-b ul{\n        padding:20px 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
