// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-container{
    position: absolute;
    top: 0px;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(255,255,255,0.80) 22%, rgba(255,255,255,0.3239889705882353) 70%);
    z-index: 100;
}

.navbar{
    width: 100%;
}

.navbar ul {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.navbar ul li {
    margin-left: 10px;
    z-index: 1000;
}

.logo-li img{
    width: 70px;
}

.name-li img{
    width: 150px;
}

@media(max-width:480px){
    .logo-li img{
        width: 50px;
    }

    .name-li img{
        width: 115px;
    }
}

@media(max-width:320px){
    .logo-li img{
        width: 30px;
    }

    .name-li img{
        width: 90px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Navbar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,sBAAsB;IACtB,yGAAyG;IACzG,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".navbar-container{\n    position: absolute;\n    top: 0px;\n    width: 100%;\n    background: rgb(0,0,0);\n    background: linear-gradient(180deg, rgba(255,255,255,0.80) 22%, rgba(255,255,255,0.3239889705882353) 70%);\n    z-index: 100;\n}\n\n.navbar{\n    width: 100%;\n}\n\n.navbar ul {\n    display: flex;\n    align-items: center;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n.navbar ul li {\n    margin-left: 10px;\n    z-index: 1000;\n}\n\n.logo-li img{\n    width: 70px;\n}\n\n.name-li img{\n    width: 150px;\n}\n\n@media(max-width:480px){\n    .logo-li img{\n        width: 50px;\n    }\n\n    .name-li img{\n        width: 115px;\n    }\n}\n\n@media(max-width:320px){\n    .logo-li img{\n        width: 30px;\n    }\n\n    .name-li img{\n        width: 90px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
