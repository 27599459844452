// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container{
    padding: 100px;
}

.about-container h2{
    text-align: center;
    font-weight: 700;
    margin: 50px 50px 10px 50px;
}

.about-container h3{
    text-align: center;
    letter-spacing: -5px;
    margin-bottom: 50px;
    color: #C9C44E;
}

.about-text-container p{
    text-align: center;
    margin: 0 60px 0px 60px;
}

.about-text-container a{
    text-align: center!important;
    color: teal !important;
    font-weight: 600;
    display: block;
    text-decoration: underline;
}

@media(max-width:480px){
    .about-container{
        padding: 10px;
        padding-bottom: 100px;
    }
}

@media(max-width:320px){
    .about-container h2{
        margin: 30px 25px 10px 25px;
        font-size: 1rem;
    }

    .about-container h3{
        margin-bottom: 30px;
    }

    .about-text-container p{
        font-size: 0.75rem;
        margin: 0 30px 20px 30px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,4BAA4B;IAC5B,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;IACd,0BAA0B;AAC9B;;AAEA;IACI;QACI,aAAa;QACb,qBAAqB;IACzB;AACJ;;AAEA;IACI;QACI,2BAA2B;QAC3B,eAAe;IACnB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,kBAAkB;QAClB,wBAAwB;IAC5B;AACJ","sourcesContent":[".about-container{\n    padding: 100px;\n}\n\n.about-container h2{\n    text-align: center;\n    font-weight: 700;\n    margin: 50px 50px 10px 50px;\n}\n\n.about-container h3{\n    text-align: center;\n    letter-spacing: -5px;\n    margin-bottom: 50px;\n    color: #C9C44E;\n}\n\n.about-text-container p{\n    text-align: center;\n    margin: 0 60px 0px 60px;\n}\n\n.about-text-container a{\n    text-align: center!important;\n    color: teal !important;\n    font-weight: 600;\n    display: block;\n    text-decoration: underline;\n}\n\n@media(max-width:480px){\n    .about-container{\n        padding: 10px;\n        padding-bottom: 100px;\n    }\n}\n\n@media(max-width:320px){\n    .about-container h2{\n        margin: 30px 25px 10px 25px;\n        font-size: 1rem;\n    }\n\n    .about-container h3{\n        margin-bottom: 30px;\n    }\n\n    .about-text-container p{\n        font-size: 0.75rem;\n        margin: 0 30px 20px 30px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
