// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container{
    position: absolute;
    top: 60%;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 100;
}

.searchform-container{
    width: 40%;
}

.searchform-container input{
    padding: 20px;
    border-radius: 0px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    width: 100%;
}

::placeholder {
    color:white;
    font-family: 'Raleway', sans-serif;;
    letter-spacing: 0.15rem;
}

@media(max-width:768px){
    .search-container{
        top: 40%;   
    }

    .searchform-container input{
        font-size: 0.55rem;
        padding: 10px;
    }
}

@media(max-width:480px){
    .searchform-container{
        width: 50%;
    }

    .search-container{
        top: 15%;
    }
}

@media(max-width:320px){
    .searchform-container{
        width: 50%;
    }

    .search-container{
        top: 13%;
    }

    .searchform-container input{
        font-size: 0.5rem;
        padding: 5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Search.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,oCAAoC;IACpC,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,kCAAkC;IAClC,uBAAuB;AAC3B;;AAEA;IACI;QACI,QAAQ;IACZ;;IAEA;QACI,kBAAkB;QAClB,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,QAAQ;IACZ;AACJ;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,QAAQ;IACZ;;IAEA;QACI,iBAAiB;QACjB,YAAY;IAChB;AACJ","sourcesContent":[".search-container{\n    position: absolute;\n    top: 60%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    z-index: 100;\n}\n\n.searchform-container{\n    width: 40%;\n}\n\n.searchform-container input{\n    padding: 20px;\n    border-radius: 0px;\n    border: 2px solid white;\n    background-color: rgba(0, 0, 0, 0.5);\n    color: white;\n    width: 100%;\n}\n\n::placeholder {\n    color:white;\n    font-family: 'Raleway', sans-serif;;\n    letter-spacing: 0.15rem;\n}\n\n@media(max-width:768px){\n    .search-container{\n        top: 40%;   \n    }\n\n    .searchform-container input{\n        font-size: 0.55rem;\n        padding: 10px;\n    }\n}\n\n@media(max-width:480px){\n    .searchform-container{\n        width: 50%;\n    }\n\n    .search-container{\n        top: 15%;\n    }\n}\n\n@media(max-width:320px){\n    .searchform-container{\n        width: 50%;\n    }\n\n    .search-container{\n        top: 13%;\n    }\n\n    .searchform-container input{\n        font-size: 0.5rem;\n        padding: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
