// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-caption{
    width: 100%!important;
    right: 0px!important;
    bottom: 0px!important;
    left: 0px!important;
    padding-left: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    background-color: rgba(0, 0, 0, 0.5);
}

.carousel-caption h3{
    color: white;
    width: 100%;
    text-align: left!important;
}

.carousel{
    height: 90vh;
    width: 100%;
    overflow: hidden;
}

.carousel img{
    height: 90vh;
    width: 100vw;
}

@media(max-width:480px){
    .carousel-caption{
        padding-left: 20px;
        padding-top: 5px;
        padding-bottom:5px ;
    }

    .carousel{
        width: 100vw;
        height: 35vh;
    }

    .carousel img{
        width: 100%;
        height: 35vh;
    }
}

@media(max-width:400px){
    .carousel-caption h3{
        font-size: 0.6rem;
        color: white;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Slider/Slider.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,oBAAoB;IACpB,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;IACpB,yBAAyB;IACzB,sBAAsB;IACtB,oCAAoC;AACxC;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI;QACI,kBAAkB;QAClB,gBAAgB;QAChB,mBAAmB;IACvB;;IAEA;QACI,YAAY;QACZ,YAAY;IAChB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,iBAAiB;QACjB,YAAY;IAChB;AACJ","sourcesContent":[".carousel-caption{\n    width: 100%!important;\n    right: 0px!important;\n    bottom: 0px!important;\n    left: 0px!important;\n    padding-left: 50px;\n    padding-top: 15px;\n    padding-bottom: 15px;\n    text-transform: uppercase;\n    letter-spacing: 0.5rem;\n    background-color: rgba(0, 0, 0, 0.5);\n}\n\n.carousel-caption h3{\n    color: white;\n    width: 100%;\n    text-align: left!important;\n}\n\n.carousel{\n    height: 90vh;\n    width: 100%;\n    overflow: hidden;\n}\n\n.carousel img{\n    height: 90vh;\n    width: 100vw;\n}\n\n@media(max-width:480px){\n    .carousel-caption{\n        padding-left: 20px;\n        padding-top: 5px;\n        padding-bottom:5px ;\n    }\n\n    .carousel{\n        width: 100vw;\n        height: 35vh;\n    }\n\n    .carousel img{\n        width: 100%;\n        height: 35vh;\n    }\n}\n\n@media(max-width:400px){\n    .carousel-caption h3{\n        font-size: 0.6rem;\n        color: white;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
