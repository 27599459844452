// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse-navbar-container{
    background-color: rgba(67, 67, 67, 0.27);
}

.items-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 100px;
}

.item-container{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px 10px 20px 10px;
    border-bottom: 2px solid rgba(67, 67, 67, 0.27);
}

.item-container a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.item-container h3{
    color: rgb(81, 80, 80);
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-top: 20px;
    margin-bottom: 10px;
    transition: 0.5s ease-in-out;
    font-size: 1.35rem;
    text-align: center;
}

.item-container h3:hover{
    font-weight: 700;
    font-size: 1.5rem;
}

.item-container img{
    width: 80%;
    transition: 0.3s ease-in-out;
}

.item-container img:hover{
    width: 85%;
}

.items-container h2{
    margin-top: 30px;
}

@media(max-width:480px){
    .item-container{
        width: 100%;
        margin: 20px 30px;
    }
}







`, "",{"version":3,"sources":["webpack://./src/components/Browse/Browse.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;IAC3B,+CAA+C;AACnD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,4BAA4B;IAC5B,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,4BAA4B;AAChC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,WAAW;QACX,iBAAiB;IACrB;AACJ","sourcesContent":[".browse-navbar-container{\n    background-color: rgba(67, 67, 67, 0.27);\n}\n\n.items-container{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-evenly;\n    margin-bottom: 100px;\n}\n\n.item-container{\n    width: 30%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin: 60px 10px 20px 10px;\n    border-bottom: 2px solid rgba(67, 67, 67, 0.27);\n}\n\n.item-container a{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.item-container h3{\n    color: rgb(81, 80, 80);\n    text-transform: uppercase;\n    letter-spacing: 0.2rem;\n    margin-top: 20px;\n    margin-bottom: 10px;\n    transition: 0.5s ease-in-out;\n    font-size: 1.35rem;\n    text-align: center;\n}\n\n.item-container h3:hover{\n    font-weight: 700;\n    font-size: 1.5rem;\n}\n\n.item-container img{\n    width: 80%;\n    transition: 0.3s ease-in-out;\n}\n\n.item-container img:hover{\n    width: 85%;\n}\n\n.items-container h2{\n    margin-top: 30px;\n}\n\n@media(max-width:480px){\n    .item-container{\n        width: 100%;\n        margin: 20px 30px;\n    }\n}\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
