import React, {useEffect, useState} from 'react'
import axios from 'axios'

function Related(data) {
    const params = {params: data.params}
    console.log(data.params)
    useEffect(() => {
       
    }, []) 
    return (
        <div>
            
        </div>
    )
}

export default Related
