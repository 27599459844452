// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html{
  overflow-x: hidden;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 15px;
  line-height: 25px;
  color: #4a4a4a;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;AACpB;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EA0DE,SAAS;EACT,UAAU;EACV,SAAS;EACT,UAAU;AACZ;;AAEA;;EAEE,UAAU;EACV,SAAS;EACT,gBAAgB;AAClB;;AAEA;;;;;;;EAOE,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;EAEE,kCAAkC;EAClC,gBAAgB;EAChB,sBAAsB;EACtB,eAAe;EACf,0BAA0B;EAC1B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;;;;;;EAME,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;500;700;900&display=swap');\n\nbody, html{\n  overflow-x: hidden;\n}\n\nhtml,\nbody,\ndiv,\nspan,\napplet,\nobject,\niframe,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\nblockquote,\ndiv pre,\na,\nabbr,\nacronym,\naddress,\nbig,\ncite,\ncode,\ndel,\ndfn,\nem,\nfont,\nimg,\nins,\nkbd,\nq,\ns,\nsamp,\nsmall,\nstrike,\nstrong,\nsub,\nsup,\ntt,\nvar,\nb,\nu,\ni,\ncenter,\ndl,\ndt,\ndd,\nol,\nul,\nli,\nfigure,\nheader,\nnav,\nsection,\narticle,\naside,\nfooter,\nfigcaption {\n  margin: 0;\n  padding: 0;\n  border: 0;\n  outline: 0;\n}\n\nul,\nli {\n  padding: 0;\n  margin: 0;\n  list-style: none;\n}\n\nheader,\nnav,\nsection,\narticle,\naside,\nfooter,\nhgroup {\n  display: block;\n}\n\n* {\n  box-sizing: border-box;\n}\n\nhtml,\nbody {\n  font-family: 'Raleway', sans-serif;\n  font-weight: 400;\n  background-color: #fff;\n  font-size: 16px;\n  -ms-text-size-adjust: 100%;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\na {\n  text-decoration: none !important;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin-top: 0px;\n  margin-bottom: 0px;\n}\n\nul {\n  margin-bottom: 0px;\n}\n\np {\n  font-size: 15px;\n  line-height: 25px;\n  color: #4a4a4a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
